import React, { useState } from 'react';
import './App.css';
import { indrasnet, nectar, sanovo, tenantApps, thornicoit } from './Constanst';
import DownloadButton from './DownloadButton';

function App() {
  // const fallbackLogo = "https://cdn-icons-png.flaticon.com/512/28/28869.png";
  
  const [tenantName, setTenant] = useState<string>(undefined!);
  const [showApp, setShowApp] = useState<boolean>(false);

  const onFindApp = () => {
    const input = document.getElementById("input") as HTMLInputElement;
    if (input?.value !== undefined) {
      let lowerCaseValue = input.value.toLocaleLowerCase();
      if (tenantApps[lowerCaseValue] || lowerCaseValue === thornicoit) {
        // user searched for a correct app
        // check if previous search was also correct so we can animate out and then in
        if (tenantApps[tenantName]) {
          setShowApp(false);
          setTimeout(() => {
            setTenant(lowerCaseValue);
            setShowApp(true);
          }, 500);
        } else {
          setTenant(lowerCaseValue);
          setShowApp(true);
        }
      } else {
        setShowApp(false);
        // wait for animation before setting tenant
        setTimeout(() => {
          setTenant(lowerCaseValue);
        }, 500);
      }
    }
  }

  return (
    <div className="App">
      <div className="content-container">
        <h1>IntraActive android custom app</h1>
        <h2>Enter your tenant or app name:</h2>
        <div className="input-wrapper">
          <input
            id="input"
            className="input"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // Cancel the default action, if needed
                e.preventDefault();
                onFindApp();
              }
            }}
          />
          <button
            className="input-button"
            onClick={() => onFindApp()}
          >
            FIND APP
          </button>
        </div>
        <div className="download-button-container">
          <DownloadButton
            showApp={showApp && tenantName === thornicoit}
            tenantName={sanovo}
          />
          <DownloadButton
            showApp={showApp && tenantName === thornicoit}
            tenantName={indrasnet}
          />
          <DownloadButton
            showApp={showApp && tenantName === thornicoit}
            tenantName={nectar}
          />
          <DownloadButton
            showApp={showApp && tenantName !== thornicoit}
            tenantName={tenantName}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
