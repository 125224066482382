import './App.css';
import { baseUrl, tenantApps } from './Constanst';

type DownloadButtonProps = {
    showApp: boolean;
    tenantName: string;
}

function DownloadButton(props: DownloadButtonProps) {
  return (
    <a
        style={{
            opacity: props.showApp ? 1 : 0,
            width: props.showApp ? 125 : 0,
            height: props.showApp ? 125 : 0,
            borderRadius: props.showApp ? 25 : 1,
            margin: props.showApp ? "0 15px" : 0
        }}
        className={`download-icon-wrapper ${props.showApp ? "visible" : ""}`}
        href={baseUrl + tenantApps[props.tenantName]?.link}
        download={`${props.tenantName}-app`}
        >
        <img
            draggable={false}
            className="download-icon"
            src={baseUrl + tenantApps[props.tenantName]?.logo}
        />
    </a>
  );
}

export default DownloadButton;
