export const baseUrl = "https://intraactivestorage.blob.core.windows.net/apps/";
export const thornicoit = "thornicoit";
export const sanovo = "sanovo";
export const indrasnet = "indrasnet";
export const nectar = "nectar";
export const tenantApps: { [name: string]: {link: string, logo: string} } = {
    "alkabello": {
      link: "alkabello/app-iaally-release.apk",
      logo: "alkabello/alkabello.png"
    },
    "ally": {
      link: "alkabello/app-iaally-release.apk",
      logo: "alkabello/alkabello.png"
    },
    "cnbdo": {
      link: "cnbdo/app-iabdointranett-release.apk",
      logo: "cnbdo/bdointranett.png"
    },
    "bdointranett": {
      link: "cnbdo/app-iabdointranett-release.apk",
      logo: "cnbdo/bdointranett.png"
    },
    "ecco": {
      link: "ecco/app-iaecconet-release.apk",
      logo: "ecco/ecconet2021.png"
    },
    "ecconet": {
      link: "ecco/app-iaecconet-release.apk",
      logo: "ecco/ecconet2021.png"
    },
    "naturgas": {
      link: "naturgas/app-iaevidanet-release.apk",
      logo: "naturgas/evidanet.png"
    },
    "evidanet": {
      link: "naturgas/app-iaevidanet-release.apk",
      logo: "naturgas/evidanet.png"
    },
    "eurowind": {
      link: "eurowind/app-iaewersite-release.apk",
      logo: "eurowind/ewersite02.png"
    },
    "ewersite": {
      link: "eurowind/app-iaewersite-release.apk",
      logo: "eurowind/ewersite02.png"
    },
    "nectar": { // tenant is "thornicoit"
      link: "nectar/app-iahummel-release.apk",
      logo: "nectar/nectar.png"
    },
    "indrasnet": { // tenant is "thornicoit"
      link: "indrasnet/app-iaindrasnet-release.apk",
      logo: "indrasnet/indrasnet.png"
    },
    "sanovo": { // tenant is "thornicoit"
      link: "sanovo/app-iasanovo-release.apk",
      logo: "sanovo/sanovo.png"
    },
    "mysanovo": { // tenant is "thornicoit"
      link: "sanovo/app-iasanovo-release.apk",
      logo: "sanovo/sanovo.png"
    },
    "ambucloud": {
      link: "ambucloud/app-ialifeline-release.apk",
      logo: "ambucloud/ambucloud_2021.png"
    },
    "ambu": {
      link: "ambucloud/app-ialifeline-release.apk",
      logo: "ambucloud/ambucloud_2021.png"
    },
    "lifeline": {
      link: "ambucloud/app-ialifeline-release.apk",
      logo: "ambucloud/ambucloud_2021.png"
    },
    "tv2": {
      link: "tv2/app-iatv2-release.apk",
      logo: "tv2/tv2_2023.jpg"
    },
    "bdoscanrevisionas": {
      link: "bdoscanrevisionas/app-iawork-release.apk",
      logo: "bdoscanrevisionas/work.png"
    },
    "work": {
      link: "bdoscanrevisionas/app-iawork-release.apk",
      logo: "bdoscanrevisionas/work.png"
    },
    "nordenergiamba": {
      link: "nordenergiamba/app-ianordenergi-release.apk",
      logo: "nordenergiamba/nordenergi.png"
    },
    "nordenergi": {
      link: "nordenergiamba/app-ianordenergi-release.apk",
      logo: "nordenergiamba/nordenergi.png"
    },
    "palsgaardcom": {
      link: "palsgaardcom/app-iapalsintra-release.apk",
      logo: "palsgaardcom/palsgaardcom.png"
    },
    "palsgaard": {
      link: "palsgaardcom/app-iapalsintra-release.apk",
      logo: "palsgaardcom/palsgaardcom.png"
    },
    "palsintra": {
      link: "palsgaardcom/app-iapalsintra-release.apk",
      logo: "palsgaardcom/palsgaardcom.png"
    },
    "applus": {
      link: "applusglobal/app-iaapplusglobal-release.apk",
      logo: "applusglobal/applusglobal.png"
    },
    "applusglobal": {
      link: "applusglobal/app-iaapplusglobal-release.apk",
      logo: "applusglobal/applusglobal.png"
    }
  }